import { IMobxClubModelProps } from 'models/mobxClubModel';

const njurundaok: IMobxClubModelProps = {
  title: 'Njurunda OK',
  defaultLanguage: 'sv',
  map: {
    center: [17.39377, 62.26092],
    defaultZoomLevel: 12,
    layers: [],
  },
  loginUrl: 'https://njurundaok.se/log_in.php',
  logoutUrl: 'https://njurundaok.se/log_out.php',
  attachmentUrl: 'https://njurundaok.se/showfile.php?iFileID=',
  titleLogo: {
    url: 'https://njurundaok.se/images/NOKBanner.png',
    width: 1000,
    height: 100,
  },
  logo: {
    url: 'https://njurundaok.se/images/icons/nok80transp.png',
    width: 80,
    height: 80,
  },
  theme: {
    palette: {
      primary: {
        main: '#800020',
        contrastText: '#ffffff',
      },
      secondary: {
        main: '#ffffff',
        contrastText: '#000000',
      },
      error: {
        main: '#aa3333',
        contrastText: '#000000',
      },
      contrastThreshold: 3,
      tonalOffset: 0.2,
    },
    typography: {
      fontFamily:
        '-apple-system,BlinkMacSystemFont,"Segoe UI",Roboto,"Helvetica Neue",Arial,sans-serif,"Apple Color Emoji","Segoe UI Emoji","Segoe UI Symbol"',
      fontSize: 12,
    },
  },
  modules: [
    {
      name: 'News',
      addUrl: 'https://njurundaok.se/nyheter/save.php',
      deleteUrl: 'https://njurundaok.se/nyheter/delete.php',
      updateUrl: 'https://njurundaok.se/nyheter/save.php',
      queryUrl: 'https://njurundaok.se/nyheter/jsonNewsQuery.php',
    },
    {
      name: 'Calendar',
      queryUrl: 'https://njurundaok.se/kalender/jsonCalendarQuery.php',
      addUrl: 'https://njurundaok.se/kalender/saveCalendar.php',
      deleteUrl: 'https://njurundaok.se/kalender/delete.php',
      updateUrl: 'https://njurundaok.se/kalender/saveCalendar.php',
    },
    { name: 'ScoringBoard' },
    { name: 'Eventor' },
    {
      name: 'Results',
      addUrl: 'https://njurundaok.se/result/save.php',
      deleteUrl: 'https://njurundaok.se/result/delete.php',
      updateUrl: 'https://njurundaok.se/result/save.php',
      queryUrl: 'https://njurundaok.se/result/jsonResultQuery.php',
    },
    {
      name: 'Users',
      addUrl: 'https://njurundaok.se/users/save.php',
      deleteUrl: 'https://njurundaok.se/users/delete.php',
      updateUrl: 'https://njurundaok.se/users/save.php',
      queryUrl: 'https://njurundaok.se/users/jsonUserQuery.php',
    },
    { name: 'Photo' },
    {
      name: 'HTMLEditor',
      addUrl: 'https://njurundaok.se/htmlEditor/save.php',
      deleteUrl: 'https://njurundaok.se/htmlEditor/delete.php',
      updateUrl: 'https://njurundaok.se/htmlEditor/save.php',
      queryUrl: 'https://njurundaok.se/htmlEditor/jsonHtmlEditorQuery.php',
    },
  ],
  links: [{ name: 'SOFT', url: 'https://www.svenskorientering.se' }],
  sports: ['Orientering'],
  eventor: {
    organisationId: 274,
    districtOrganisationId: 14,
  },
  corsProxy: 'https://njurundaok.se/proxy.php?csurl=',
  eventorCorsProxy: 'https://njurundaok.se/eventorProxyWithCache.php',
  oldUrl: 'https://njurundaok.org/',
  sponsors: [],
};

export default njurundaok;
